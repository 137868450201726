<template>
  <v-container fluid>
    <v-card-title>Changement de mot de passe</v-card-title>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="spinner"
      class="EoleBlueLighten"
      :opacity="1"
    >
    </loading>
    <v-form>
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12" sm="8" md="6">
            <v-form ref="formPassword" v-model="validityFormPassword" lazy-validation> </v-form>
            <v-text-field
              id="new-password"
              :success="password.length >= 8"
              v-model="password"
              :type="isNewPasswordVisible ? 'text' : 'password'"
              :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              label="Nouveau mot de passe"
              outlined
              dense
              hint="Le mot de passe doit contenir 8 caractères minimum"
              persistent-hint
              @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              :error-messages="passwordErrorMessage"
              :rules="[passwordRules.required, passwordRules.min]"
            ></v-text-field>
            <v-text-field
              id="confirm-new-password"
              :success="passwordValidation.length >= 8 && password === passwordValidation"
              v-model="passwordValidation"
              :type="isCPasswordVisible ? 'text' : 'password'"
              :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              label="Confirmer le nouveau mot de passe"
              outlined
              dense
              class="mt-3"
              @click:append="isCPasswordVisible = !isCPasswordVisible"
              :error-messages="passwordErrorMessage"
              :rules="[passwordRules.required, passwordRules.min]"
              hint="Le mot de passe doit contenir 8 caractères minimum"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-btn
          :disabled="password.length < 8 || passwordValidation.length < 8"
          color="primary"
          class="me-3 mt-3"
          @click="validateFormPassword"
        >
          Modifier
        </v-btn>
        <v-btn color="secondary" outlined class="mt-3"> Annuler </v-btn>
      </v-card-text>
    </v-form>
  </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js"
import { ref } from "@vue/composition-api"
import config from "@/views/config/config"
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    validityFormPassword: true,
    waitTimeout: false,
    password: "",
    passwordValidation: "",
    passwordErrorMessage: "",
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 8 || "Minimum 8 caractères",
    },
  }),
  components: {
    Loading,
  },
  methods: {
    validateFormPassword() {
      if (this.password.length >= 8 && this.password === this.passwordValidation) {
        if (this.$refs.formPassword.validate()) {
          this.passwordErrorMessage = ""

          this.updatePassword()

          this.password = ""
          this.passwordValidation = ""
        }
      } else {
        this.passwordErrorMessage = "Les mots de passes doivent correspondre"
      }
    },
    logOut() {
      let dataSaveStorage = localStorage.getItem("data_save")

      if (dataSaveStorage === "false") {
        localStorage.clear()
      } else {
        localStorage.removeItem("master_account_linked")
        localStorage.removeItem("linked")
        localStorage.removeItem("complete_name")
        localStorage.removeItem("lastname")
        localStorage.removeItem("firstname")
        localStorage.removeItem("user_id")
        localStorage.removeItem("agency")
        localStorage.removeItem("grade")
        localStorage.removeItem("phone")
        localStorage.removeItem("is_verify")
        localStorage.removeItem("update_time")
        localStorage.removeItem("agent_for")
      }

      window.location.reload()
    },
    async updatePassword() {
      let idUser = localStorage.getItem("user_id")
      this.waitTimeout = true
      // await pause(3000)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response

        if (this.password === this.passwordValidation) {
          if (localStorage.getItem("linked") != null) {
            response = await fetch(
              `${config.apiUri}/php/pages/accounts.php?id=${idUser}&changePassword=true&newPassword=${this.password}`,
              {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
          } else {
            response = await fetch(`${config.apiUri}/php/pages/users.php?id=${idUser}&newPassword=${this.password}`, {
              mode: "cors",
              method: "PUT",
              headers: headers,
            })
          }
        }

        this.$toast.info(`Vous venez de modifier votre mot de passe avec succès.`, {
          position: "bottom-right",
          timeout: 5000,
        })

        setTimeout(() => {
          this.logOut()
        }, 5000)
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
  },
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref("12345678")
    const newPassword = ref("87654321")
    const cPassword = ref("87654321")

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
